
import { mapGetters } from 'vuex';
import GateNotificationTooltip from '@razlet/sdk/lib/components/office/notification/tooltip';
import GateNotificationCenter from '@razlet/sdk/lib/components/office/notification/center';
import WidgetComponent from '@razlet/sdk/lib/components/chat/widget';
import BonusesView from '@razlet/sdk/lib/components/asb/user/bonuses';
import PricingView from '@razlet/sdk/lib/components/asb/pricing/view'; 
import auth from '~/mixins/auth';
import search from '~/mixins/search';
import access from '~/mixins/access';
import FooterBlock from '~/components/footer-block';
import MainHeader from '~/components/main-header';

const pricingFormRoutes = [
  'search-uid', 
];
const withoutContainerRoutes = [
  'all',
  'bad-access',
  'index', 
  'login', 
  'citylist', 
  'contacts',
  'bid-return-id',
  'bid-return-create-id',
  'news-all',
  'news',
  'order-id-refund',
  'order-id-refund-voluntary',
  'flight-departure-arrival',
];

export default {
  components: {
    FooterBlock, 
    PricingView, 
    MainHeader,
    BonusesView,
    GateNotificationTooltip,
    GateNotificationCenter,
    WidgetComponent,
  },
  mixins: [auth, search, access],
  data() {
    return {
      windowCommunication: null,
      syncDate: '',
    };
  },
  computed: {
    ...mapGetters(['isPushRegistered']),
    isRouteWithPricingForm() {
      return pricingFormRoutes.includes(this.$route.name);
    },
    isRouteWithoutContainer() {
      return withoutContainerRoutes.includes(this.$route.name);
    },
    isRouteAccount() {
      return this.$route.name.startsWith('account-');
    },
    layoutClasses() {
      let classes = `theme--${this.currentTheme}`;
      if (this.isAgentSite) classes += ` theme--agent-${this.currentTheme}`;
      return classes;
    },
  },
  watch: {
    '$route': {
      handler(value) {
        if (value) {
          if (this.$refs.widget) this.widgetMounted();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.followNotificatorChannel();
    window.addEventListener('resize', () => {
      this.$store.commit('setMobile', window.innerWidth <= 992);
    });
  },
  beforeDestroy() {
    this.unfollowNotificatorChannel();
    window.removeEventListener('resize', () => {
      this.$store.commit('setMobile', window.innerWidth <= 992);
    });
  },
  methods: {
    widgetMounted() {
      if (this.$route.query && this.$route.query.chatId) {
        this.$bus.$emit('widget-open-chat', this.$route.query.chatId, true);
        const query = { ...this.$route.query };
        delete query.chatId;
        this.$router.replace({ ...this.$route, query });
      }
    },
    followNotificatorChannel () {
      this.windowCommunication = new BroadcastChannel(window.origin);
      this.windowCommunication.onmessage = (event) => {
        if (!event || !event.data.data) return;
        if (event.data.data.type === 'syncDate') {
          const { message } = event.data.data;
          this.syncDate = message;
        }
        else if (event.data.data.type === 'authError') {
          this.$interceptor.intercept();
        }
      };
    },
    unfollowNotificatorChannel () {
      if (!this.windowCommunication) return;
      this.windowCommunication.close();
    },
  },
};
