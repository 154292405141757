import { render, staticRenderFns } from "./news-list.vue?vue&type=template&id=0d4b0ac2"
import script from "./news-list.vue?vue&type=script&lang=js"
export * from "./news-list.vue?vue&type=script&lang=js"
import style0 from "./news-list.vue?vue&type=style&index=0&id=0d4b0ac2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsCard: require('/opt/razlet.ru.v2/src/components/news/card.vue').default,SuluNewsList: require('/opt/razlet.ru.v2/node_modules/@razlet/sulu-sdk/src/components/news/list.vue').default})
