import { USER, AGENT, CORPORATE, AIRPORT_CASHIER } from '@razlet/sdk/lib/constants/roles';

export default {
  computed: {
    filteredNavItems() {
      if (!this.isUser || !this.user.model.roles) return [];
      return this.navItems.filter(item => !item.access.length || item.access.some(v => this.user.model.roles.includes(v)));
    },
    hasBalance() {
      return this.isUser && typeof this.$store.getters['auth/balance'] !== 'undefined';
    },
    navItems() {
      return [
        {
          name: this.$t('menu.profile'),
          routeName: 'account-profile',
          access: [USER],
        },
        {
          name: this.$t('menu.orders'),
          routeName: 'account-orders',
          access: [USER],
        },
        {
          name: this.$t('menu.operations'),
          routeName: 'account-operations',
          access: [USER],
        },
        {
          name: this.$t('menu.requests'),
          routeName: 'account-requests',
          access: [CORPORATE, AGENT],
        },
        {
          name: this.$t('menu.tickets'),
          routeName: 'account-tickets',
          access: [USER],
        },
        {
          name: this.$t('menu.passengers'),
          routeName: 'account-passengers',
          access: [USER],
        },
        {
          name: this.$t('menu.upsale'),
          routeName: 'account-upsale',
          access: [AIRPORT_CASHIER],
        },
      ];
    },
  },
};