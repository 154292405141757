
import { getSuluPage, fillSeo, getSuluApi, handleResponse } from '@razlet/sulu-sdk/lib/utils/helpers';
import Sulu from '@razlet/sulu-sdk/lib/entities/factory';
import PricingForm from '@razlet/sdk/lib/components/asb/pricing/form';
import search from '~/mixins/search';

export default {
  name: 'index',
  layout: 'default',
  components: {
    PricingForm,
  },
  mixins: [search],
  async asyncData ({ store, route, redirect }) {
    if (process.env.type !== 'agent') {
      const results = await Promise.allSettled([
        // eslint-disable-next-line no-console
        getSuluPage({ store, route, error: console.error, redirect }),
        getSuluApi({ store, redirect, path: `news-list` }),
      ]);

      const [result, news] = handleResponse(results);
      return { result: result || {}, news: news || {} };
    }
    return { result: {}, news: {} };
  },
  data() {
    return {
      uid: null,
      news: {},
      result: {},
      loading: true,
    };
  },
  created() {
    this.fillSulu(this.result, this.news);
  },
  mounted() {
    this.$bus.$on('locale-changed', async() => {
      this.loading = true;
      window.scrollTo(0, 0);
      this.$nuxt.refresh().then(() => {
        this.fillSulu(this.result, this.news);
      });
    });
  },
  destroyed() {
    this.$bus.$off('locale-changed');
  },
  methods: {
    fillSulu(res, news) {
      const suluFactory = new Sulu();
      this.result = suluFactory.create(res);
      this.news = suluFactory.create(news);
      this.loading = false;
    },
  },
  head() {
    return {
      ...fillSeo(this, this.result.seo),
    };
  },
};
