
import { mapGetters } from 'vuex';
import elsomLogo from '~/static/elsom.png';
import snowBg from '~/assets/img/snow-bg.png';

export default {
  name: 'footer-block',
  data() {
    return {
      elsomLogo,
      snowBg,
      year: (new Date()).getFullYear(),
    };
  },
  computed: {
    ...mapGetters('sulu', ['menu']),
  },
};
